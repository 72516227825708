import React from 'react';
import ControlledCheckbox from '@components/FormInputs/ControlledCheckbox';
import ControlledRadioButtons from '@components/FormInputs/ControlledRadioButtons';
import { Button } from '@GDM/Button/Button';
import { RadioOption } from '@GDM/forms';
import useTranslation from '@hooks/useTranslation';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { CustomReportingGlobalQuery, ReportMetric, ReportMetricData } from '../types';
import { isQueryTimeBased } from '../utils';
import { useReportCharts } from './Chart/hooks';
import ReportCharts from './Chart/ReportCharts';
import styles from './custom-reporting-report.module.scss';
import { useUniqueMetricRadioOptions } from './hooks';
import { useReportTableColumnsAndRows } from './Table/hooks';
import { FullPeriodReportTable, TimeBasedReportTable } from './Table/ReportTable';
import { DisplayOption, ReportVisualizationFilters } from './types';
import { exportCustomRportToCsv } from './utils';
import { useUser } from '@context/User.context';
import { Permission } from '@utils/types/permission';

export type CustomReportProps = {
  data: ReportMetricData[];
  query: CustomReportingGlobalQuery;
};

const ALL_METRICS: Record<ReportMetric, true> = {
  revenue: true,
  production: true,
  contract_nb: true,
  unit_price: true,
  business_plan: true,
  power: true,
};

const NO_METRIC: Record<ReportMetric, false> = {
  revenue: false,
  production: false,
  contract_nb: false,
  unit_price: false,
  business_plan: false,
  power: false,
};

const DISPLAY_OPTIONS: RadioOption<DisplayOption>[] = [
  { label: 'common.table', value: 'table' },
  { label: 'common.chart', value: 'chart' },
];

const CustomReport = ({ data, query }: CustomReportProps) => {
  const { control, watch, handleSubmit } = useForm<ReportVisualizationFilters>({
    defaultValues: {
      display: 'table',
      metrics: ALL_METRICS,
      uniqueMetric: 'revenue',
    },
  });
  const { display, metrics, uniqueMetric } = watch();

  const isTimeBased = isQueryTimeBased(query);
  const tableMetrics = isTimeBased ? { ...NO_METRIC, [uniqueMetric]: true } : metrics;
  const radioMode = isTimeBased && display == 'table';

  const { t } = useTranslation();
  const uniqueMetricRadioOptions = useUniqueMetricRadioOptions();

  const tableData = useReportTableColumnsAndRows(data, query, tableMetrics);
  const chartsProps = useReportCharts({ data, query, metrics });

  const user = useUser();
  const etrmPermission: Permission[] = ["display:etrm"];

  return (
    <form
      onSubmit={handleSubmit(exportCustomRportToCsv(tableData, query, uniqueMetric, t))}
      className={classNames(styles.container, 'mt-4', 'p-4')}
    >
      <div className={styles.header}>
        <h2 className="m-0">{t('reporting.generated_report')}</h2>
        <div className="d-flex">
          <ControlledRadioButtons name="display" control={control} options={DISPLAY_OPTIONS} />
          <Button
            size="xs"
            variant="primary-2"
            type="submit"
            icon="Download"
            className="ml-4"
            data-cy="export-submit-button"
            text="common.download"
          />
        </div>
      </div>
      <div className="mt-4">
        {radioMode ? (
          <ControlledRadioButtons name="uniqueMetric" options={uniqueMetricRadioOptions} control={control} />
        ) : (
          <>
            <ControlledCheckbox name="metrics.revenue" label={user?.isAuthorized(etrmPermission)? 'common.pnl' : 'common.revenues'} control={control} />
            <ControlledCheckbox
              className="ml-4"
              name="metrics.production"
              label="common.production"
              control={control}
            />
            <ControlledCheckbox
              className="ml-4"
              name="metrics.contract_nb"
              label="common.contract_nb"
              control={control}
            />
            <ControlledCheckbox className="ml-4" name="metrics.unit_price" label="common.price" control={control} />
            <ControlledCheckbox
              className="ml-4"
              name="metrics.business_plan"
              label="monitoring.installation.revenue.business_plan"
              control={control}
            />
            <ControlledCheckbox className="ml-4" name="metrics.power" label="common.power" control={control} />
          </>
        )}
      </div>
      <div className="mt-4">
        {display === 'table' && tableData.isTimeBased && (
          <TimeBasedReportTable columns={tableData.columns} rows={tableData.rows} focus={query.focus} />
        )}
        {display === 'table' && !tableData.isTimeBased && (
          <FullPeriodReportTable columns={tableData.columns} rows={tableData.rows} focus={query.focus} />
        )}
        {display === 'chart' && <ReportCharts chartsProps={chartsProps} />}
      </div>
    </form>
  );
};

export default CustomReport;
