import React, { useEffect, useLayoutEffect, useState } from 'react';
import ControlledCheckbox from '@components/FormInputs/ControlledCheckbox';
import ControlledRadioButtons from '@components/FormInputs/ControlledRadioButtons';
import { ControlledToggle } from '@components/FormInputs/ControlledToggle';
import { Button } from '@GDM/Button/Button';
import { Chart } from '@GDM/Chart/Chart';
import { Table } from '@GDM/Table/Table';
import { TableBody } from '@GDM/Table/TableBody/TableBody';
import { TableHead } from '@GDM/Table/TableHead/TableHead';
import {
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import debounce from 'lodash/debounce';
import { Control, useForm } from 'react-hook-form';
import { SENSITIVITY_METRICS_FORM_NAMES_TO_LABELS, VALUATION_METRICS_FORM_NAMES_TO_LABELS } from '../constants';
import styles from '../styles.module.scss';
import { GreenstarReport, RiskAnalysisReportDisplayControlForm, RiskAnalysisReportType } from '../types';
import { useRiskAnalysisCharts } from '../useCharts';
import { useRiskAnalysisTable } from './hooks/table';
import { useFormatReportData } from './hooks/useFormatReportData';

export const RiskAnalysisReport = ({
  report: { data: rawData, filters, exportUrl },
  type,
}: {
  report: GreenstarReport;
  type: RiskAnalysisReportType;
}) => {
  const defaultValues = {
    // Valuation metrics
    volume: true,
    open_volume: true,
    clicked_volume: true,
    signed_volume: false,
    signed_hedged_volume: true,
    signed_non_hedged_volume: true,
    volume_not_signed: true,
    hcr: true,
    mtom: true,
    net_price: true,
    subsidy_price: true,
    float_price: true,
    // Sensitivity metrics
    price_outright: true,
    profile_risk: true,
    balancing_risk: true,

    splitByMetric: false,
    display: 'table',
  };

  const { control, watch } = useForm<RiskAnalysisReportDisplayControlForm>({ defaultValues });
  const display = watch('display');
  const splitByMetric = watch('splitByMetric');
  const isReportNested = Boolean(filters.secondaryFocus) || type === 'sensitivity';

  const [expanded, setExpanded] = useState({});
  const data = useFormatReportData(rawData, isReportNested, splitByMetric);
  const { columns, rows } = useRiskAnalysisTable(data, isReportNested);

  const metricsToLabel =
    type === 'valuation' ? VALUATION_METRICS_FORM_NAMES_TO_LABELS : SENSITIVITY_METRICS_FORM_NAMES_TO_LABELS;

  const [columnFilters, setColumnFilters] = useState([
    {
      id: 'name',
      // Get the black list
      value: Object.keys(metricsToLabel)
        .filter((key) => !defaultValues[key as keyof RiskAnalysisReportDisplayControlForm])
        .map((metric): string => {
          return metricsToLabel[metric as keyof typeof metricsToLabel];
        }),
    },
  ]);

  useEffect(() => {
    const subsciption = watch((values) => {
      setColumnFilters([
        {
          id: 'name',
          // Get the black list
          value: Object.keys(values)
            .filter((key) => !values[key as keyof RiskAnalysisReportDisplayControlForm])
            .map((metric) => {
              return metricsToLabel[metric as keyof typeof metricsToLabel];
            }),
        },
      ]);
      metricsToLabel;
    });

    return subsciption.unsubscribe;
  }, [metricsToLabel, watch]);

  const table = useReactTable({
    columns,
    data: rows,
    initialState: {
      expanded: true,
    },
    state: {
      expanded,
      columnFilters,
    },
    enableExpanding: true,
    onExpandedChange: setExpanded,
    getSubRows: (row) => row.subRows || [],
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  useLayoutEffect(() => {
    table.toggleAllRowsExpanded(true);
  }, [table]);

  const charts = useRiskAnalysisCharts({
    data,
    isReportNested,
    splitByMetric,
    metricsBlackList: columnFilters[0].value,
  });

  return (
    <div className="mt-4">
      {type === 'sensitivity' && <RiskAnalysisSensitivityReportHeader control={control} exportUrl={exportUrl} />}
      {type === 'valuation' && <RiskAnalysisValuationReportHeader control={control} exportUrl={exportUrl} />}
      <div className="mt-4">
        {display === 'table' && (
          <Table>
            <TableHead table={table} />
            <TableBody table={table} />
          </Table>
        )}
        {display === 'chart' && (
          <div className={styles['chart-grid']}>
            {charts.map((chart) => (
              <Chart {...chart} key={chart.key} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const RiskAnalysisValuationReportHeader = ({
  exportUrl,
  control,
}: {
  exportUrl: string;
  control: Control<RiskAnalysisReportDisplayControlForm>;
}) => {
  return (
    <form className="d-flex justify-content-between">
      <div>
        <ControlledCheckbox className="mr-2" name="volume" label="Volume" control={control} defaultValue />
        <ControlledCheckbox
          className="mr-2"
          name="volume_not_signed"
          label="Volume Not Signed"
          control={control}
          defaultValue
        />
        <ControlledCheckbox
          className="mr-2"
          name="signed_volume"
          label="Signed Volume"
          control={control}
          defaultValue
        />
        <ControlledCheckbox
          className="mr-2"
          name="signed_non_hedged_volume"
          label="Signed Volume Not Hedged"
          control={control}
          defaultValue
        />
        <ControlledCheckbox
          className="mr-2"
          name="signed_hedged_volume"
          label="Signed Hedged Volume"
          control={control}
          defaultValue
        />
        <ControlledCheckbox className="mr-2" name="hcr" label="HCR" control={control} defaultValue />
        <ControlledCheckbox className="mr-2" name="mtom" label="MtoM" control={control} defaultValue />
        <ControlledCheckbox className="mr-2" name="net_price" label="Net Price" control={control} defaultValue />
        <ControlledCheckbox className="mr-2" name="subsidy_price" label="OA/CfD Price" control={control} defaultValue />
        <ControlledCheckbox className="mr-2" name="float_price" label="Float Price" control={control} defaultValue />
        <ControlledToggle name="splitByMetric" label="Split by Metric" control={control} />
      </div>
      <div className="d-flex">
        <ControlledRadioButtons
          name="display"
          control={control}
          options={[
            { label: 'common.table', value: 'table' },
            { label: 'common.chart', value: 'chart' },
          ]}
          defaultValue="table"
        />
        <Button
          size="xs"
          variant="primary-2"
          type="submit"
          icon="Download"
          className="ml-4"
          data-cy="export-submit-button"
          text="common.download"
          onClick={debounce(() => {
            window.location.href = exportUrl;
          })}
        />
      </div>
    </form>
  );
};

const RiskAnalysisSensitivityReportHeader = ({
  exportUrl,
  control,
}: {
  exportUrl: string;
  control: Control<RiskAnalysisReportDisplayControlForm>;
}) => {
  return (
    <form className="d-flex justify-content-between">
      <div>
        <ControlledCheckbox
          className="mr-2"
          name="price_outright"
          label="Price Outright"
          control={control}
          defaultValue
        />
        <ControlledCheckbox className="mr-2" name="profile_risk" label="Profile Risk" control={control} defaultValue />
        <ControlledCheckbox
          className="mr-2"
          name="balancing_risk"
          label="Balancing Risk"
          control={control}
          defaultValue
        />
        <ControlledToggle name="splitByMetric" label="Split by Metric" control={control} />
      </div>
      <div className="d-flex">
        <ControlledRadioButtons
          name="display"
          control={control}
          options={[
            { label: 'common.table', value: 'table' },
            { label: 'common.chart', value: 'chart' },
          ]}
          defaultValue="table"
        />

        <Button
          size="xs"
          variant="primary-2"
          type="submit"
          icon="Download"
          className="ml-4"
          data-cy="export-submit-button"
          text="common.download"
          onClick={debounce(() => {
            window.location.href = exportUrl;
          })}
        />
      </div>
    </form>
  );
};
