import { Option } from '@utils/types/common-types';
import { RiskAnalysisFocusType } from './types';

export const FOCUS_OPTIONS: Option<RiskAnalysisFocusType>[] = [
  { label: 'common.contract_type', value: 'contract_type' },
  { label: 'common.energy', value: 'energy' },
];

export const GRAPPE_OPTIONS: Option<string>[] = [];

export const VALUATION_METRICS_FORM_NAMES_TO_LABELS = {
  volume: 'Volume',
  volume_not_signed: 'Volume Not Signed',
  open_volume: 'Open Volume',
  signed_volume: 'Signed Volume',
  signed_hedged_volume: 'Signed Hedged Volume',
  signed_non_hedged_volume: 'Signed Volume Not Hedged',
  hcr: 'HCR',
  mtom: 'MtoM',
  net_price: 'Net Price',
  subsidy_price: 'OA/CfD Price',
  float_price: 'Float Price',
};

export const SENSITIVITY_METRICS_FORM_NAMES_TO_LABELS = {
  price_outright: 'Price Outright',
  profile_risk: 'Profile Risk',
  balancing_risk: 'Balancing Risk',
};
