import React, { useMemo } from 'react';
import ControlledSelect from '@components/FormInputs/ControlledSelect';
import { Button } from '@GDM/Button/Button';
import { styles } from '@GDM/Filters';
import { Table } from '@GDM/Table/Table';
import { TableBody } from '@GDM/Table/TableBody/TableBody';
import { TableHead } from '@GDM/Table/TableHead/TableHead';
import { useQuery } from '@tanstack/react-query';
import { AccessorKeyColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import fetchWrapper from '@utils/fetch';
import { v2_green_star_counter_parts_path } from '@utils/routes';
import { useForm } from 'react-hook-form';
import { useGreenStarGroups } from './queries';
import { RiskAnalysisCounterpart, RiskAnalysisCounterpartsFilter } from './types';

export const Counterparts = () => {
  const { control, watch } = useForm<RiskAnalysisCounterpartsFilter>();

  const groupsQuery = useGreenStarGroups();

  const grappeOptions =
    groupsQuery.data?.grappes.map((grappe) => ({
      value: grappe,
      label: grappe,
    })) || [];

  const holdingOptions =
    groupsQuery.data?.holdings.map((holding) => ({
      value: holding,
      label: holding,
    })) || [];

  const spvOptions =
    groupsQuery.data?.spvs.map((spv) => ({
      value: spv,
      label: spv,
    })) || [];

  // const onSubmit = async () => {
  //   const options = { spv, grappe, holding, apply_ownership_percentage };
  //   const response = await fetchWrapper(v2_green_star_counter_parts_path(options));
  //   const data = await response.json();

  //   return data;
  // };

  const { spv, grappe, holding, apply_ownership_percentage } = watch();

  const conterpartsQuery = useQuery({
    queryKey: [
      'greenstar_countereparts',
      // ...(spv || []),
      // ...(grappe || []),
      // ...(holding || []),
      // apply_ownership_percentage,
    ],
    queryFn: async (): Promise<RiskAnalysisCounterpart[]> => {
      const options = { spv, grappe, holding, apply_ownership_percentage };
      const response = await fetchWrapper(v2_green_star_counter_parts_path(options));

      return response.json();
    },
  });

  const columns = useMemo<AccessorKeyColumnDef<RiskAnalysisCounterpart>[]>(
    () => [
      {
        accessorKey: 'name',
        header: 'risk_analysis.counterpart',
      },
      { accessorKey: 'debt', header: 'risk_analysis.unpaid_to_date' },
      { accessorKey: 'termination_period_in_month', header: 'risk_analysis.termination' },
      { accessorKey: 'exposure', header: 'risk_analysis.exposure' },
      { accessorKey: 'collateral', header: 'risk_analysis.collateral' },
      { accessorKey: 'exposure_without_debt', header: 'risk_analysis.residual_exposing' },
      { accessorKey: 'rating', header: 'risk_analysis.rating' },
    ],
    [],
  );

  const table = useReactTable({
    columns,
    data: conterpartsQuery.data || [],
    getCoreRowModel: getCoreRowModel(),
  });

  console.log('render');

  return (
    <div className="p-4">
      <form className="d-flex justify-content-between mb-4" /*onSubmit={handleSubmit(onSubmit)}*/>
        <div className="d-flex gap-4">
          <ControlledSelect
            isMulti
            name="spv"
            control={control}
            options={spvOptions}
            placeholder="sales_management.owner"
            className={styles.select}
          />
          <ControlledSelect
            isMulti
            name="grappe"
            control={control}
            options={grappeOptions}
            placeholder="risk_analysis.grappe"
            className={styles.select}
          />
          <ControlledSelect
            isMulti
            name="holding"
            control={control}
            options={holdingOptions}
            placeholder="risk_analysis.holding"
            className={styles.select}
          />
          <ControlledSelect
            name="apply_ownership_percentage"
            control={control}
            options={[
              { label: '100 %', value: false },
              { label: 'risk_analysis.consolidated', value: true },
            ]}
            placeholder="risk_analysis.vision"
            className={styles.select}
            defaultValue={false}
          />
        </div>
        <div className="d-flex">
          <Button
            size="xs"
            variant="primary-2"
            type="submit"
            icon="Download"
            className="ml-4"
            data-cy="export-submit-button"
            text="common.download"
          />
        </div>
      </form>
      <Table>
        <TableHead table={table} />
        <TableBody table={table} isLoading={conterpartsQuery.isPending} />
      </Table>
    </div>
  );
};
