import React, { useMemo } from 'react';
import { Icon } from '@GDM/Icon/Icon';
import { NumberCell } from '@GDM/Table/Cells/NumberCell/NumberCell';
import { AccessorKeyColumnDef } from '@tanstack/react-table';
import classNames from 'classnames';
import styles from '../../styles.module.scss';
import {
  GreenstarFocusData,
  GreenstarReportData,
  GreenstarReportDataWithOneFocus,
  GreenstarReportMetricValues,
} from '../../types';

type GreenstarRow = {
  name: string;
  subRows: GreenstarRow[];
  values: GreenstarReportMetricValues;
};

export const useRiskAnalysisTable = (data: GreenstarReportData, isReportNested: boolean) => {
  const { rows, dates } = useMemo(() => {
    const dateSet = new Set<string>();
    const rows: GreenstarRow[] = [];

    Object.entries(data).forEach(
      ([focus1, focusDataOrMetricValuesForEachDates]: [
        string,
        GreenstarFocusData | GreenstarReportDataWithOneFocus,
      ]) => {
        const row: GreenstarRow = { name: focus1, subRows: [], values: {} };
        rows.push(row);
        const metricRows: Record<string, GreenstarRow> = {};

        Object.entries(focusDataOrMetricValuesForEachDates).forEach(
          ([dateOrSecondaryFocus, metricValuesOrFocusData]: [
            string,
            GreenstarReportMetricValues | GreenstarFocusData,
          ]) => {
            if (!isReportNested) {
              dateSet.add(dateOrSecondaryFocus);
              row.values[dateOrSecondaryFocus] = '';

              Object.entries(metricValuesOrFocusData).forEach(([metric, value]) => {
                metricRows[metric] = {
                  ...metricRows[metric],
                  name: metric,
                  subRows: [],
                  values: {
                    ...metricRows[metric]?.values,
                    [dateOrSecondaryFocus]: value,
                  },
                };
              });

              return;
            }

            const subRow: GreenstarRow = { name: dateOrSecondaryFocus, subRows: [], values: {} };
            row.subRows.push(subRow);

            Object.entries(metricValuesOrFocusData).forEach(([date, values]: [string, GreenstarReportMetricValues]) => {
              dateSet.add(date);
              row.values[date] = '';
              subRow.values[date] = '';
              Object.entries(values).forEach(([metric, value]) => {
                metricRows[metric] = {
                  ...metricRows[metric],
                  name: metric,
                  subRows: [],
                  values: {
                    ...metricRows[metric]?.values,
                    [date]: value,
                  },
                };
              });
            });

            subRow.subRows.push(...Object.values(metricRows));
          },
        );

        if (!isReportNested) row.subRows.push(...Object.values(metricRows));
      },
    );

    const dates = [...dateSet.values()].sort();

    return { dates, rows };
  }, [data, isReportNested]);

  const columns = useColumns(dates, isReportNested);

  return { columns, rows };
};

const useColumns = (dates: string[], isReportNested: boolean): AccessorKeyColumnDef<GreenstarRow>[] => {
  return useMemo(() => {
    return [
      {
        id: 'name',
        header: '',
        filterFn: (row, columnId, blacklist: string[]) => {
          const rowValue: string = row.getValue(columnId);

          return !blacklist.includes(rowValue);
        },
        accessorKey: 'name',
        cell: ({ row, getValue }) => (
          <div
            className={classNames({
              [styles['leaf-row']]: isReportNested ? row.depth === 2 : row.depth === 1,
              'pl-4': isReportNested && row.depth === 1,
            })}
          >
            {(isReportNested ? row.depth < 2 : row.depth === 0) && (
              <Icon
                name={row.getIsExpanded() ? 'ChevronDown' : 'ChevronRight'}
                className="cursor-pointer select-none"
                size={14}
                onClick={() => {
                  row.toggleExpanded();
                }}
              />
            )}{' '}
            {getValue<string>()}
          </div>
        ),
      },
      ...(dates.map<AccessorKeyColumnDef<GreenstarRow>>((dateKey) => {
        return {
          id: dateKey,
          header: dateKey,
          cell: ({ getValue }) => (
            <NumberCell
              value={getValue()}
              // fractionDigits={FRACTION_DIGITS_PER_METRIC[selectedData.metric]}
            />
          ),
          accessorKey: `values.${dateKey}`,
        };
      }) || []),
    ];
  }, [dates, isReportNested]);
};
