import React from 'react';
import { useUser } from '@context/User.context';
import { AltContent } from '@GDM/AltContent';
import { Chart } from '@GDM/Chart';
import useTranslation from '@hooks/useTranslation';
import { getSymbol } from '@utils/currency/getSymbol';
import { isANumber } from '@utils/isANumber';
import { appendColon } from '@utils/string';
import { CountryCode } from '@utils/types/countries';
import { SeriesOptionsType } from 'highcharts';
import Highcharts from 'highcharts/highstock';
import { getGroupLabel } from '../helpers/getGroupLabel';
import { mapGroupedDataToSeries } from '../helpers/mapGroupedDataToSeries';
import { mapToDataPoint } from '../helpers/mapToDataPoint';
import { isGroupedData } from '../helpers/type-helpers';
import { useRevenueContext } from '../hooks/useRevenueContext';
import { GroupedPoints, Point } from '../revenue.types';
import { CURRENCY_FORMATTING_OPTIONS, DATE_FORMATS } from './chart-config-enums';

export const RevenueChart = () => {
  const { t } = useTranslation();
  const { revenue, groupBy, resolution, isLoading, overview, selectedCurrency } = useRevenueContext();
  const { locale, geolocation } = useUser();
  const user = useUser();

  const isGrouped = isGroupedData(revenue);

  const currencyOptions = CURRENCY_FORMATTING_OPTIONS;

  currencyOptions.currency = selectedCurrency || 'EUR';

  if (!revenue || revenue.length === 0)
    return isLoading ? null : <AltContent data-cy="no-revenue-data" className="my-4" />;

  let nonGroupedRevenueData: Point[] = [];
  let groupedRevenueData: GroupedPoints[] = [];
  let businessPlanData: Point[] = [];

  if (isGrouped) {
    groupedRevenueData = revenue.map(mapGroupedDataToSeries);
  } else {
    nonGroupedRevenueData = revenue.map(mapToDataPoint);
  }

  if (resolution === 'monthly') {
    const businessPlan = overview?.find((item) => item.field === 'business_plan');

    if (businessPlan) businessPlanData = businessPlan.data.map(mapToDataPoint);
  }

  const noData =
    (!isGrouped && nonGroupedRevenueData?.every((v) => v.y === null)) ||
    (isGrouped && groupedRevenueData?.every((v) => v.noData));

  if (noData) return isLoading ? null : <AltContent data-cy="no-revenue-data" className="my-4" />;

  const forecastName = (name : String, isForecast: Boolean) => {
    if (user.permissions.includes('display:etrm'))
      return `${name}${isForecast ? ` (${t('common.mark_to_market')})` : ''}`;
    else
      return `${name}${isForecast ? ` (${t('common.forecast')})` : ''}`;
  };

  const yAxisTitle = user.permissions.includes('display:etrm') ? t('common.pnl') : t('common.revenues');

  const series: Highcharts.Options['series'] = [
    {
      name: t('monitoring.installation.revenue.total'),
      data: nonGroupedRevenueData,
      visible: !isGroupedData(revenue),
      zIndex: -1,
      type: 'column',
      color: 'var(--chart-blue)',
    },
    {
      name: t('monitoring.installation.revenue.business_plan'),
      data: businessPlanData,
      visible: !!businessPlanData.length,
      zIndex: 1,
      type: 'line',
      color: 'var(--chart-business-plan)',
    },

    ...(groupedRevenueData.map((groupedData) => {
      return {
        name: t(getGroupLabel(groupBy, groupedData.label, geolocation[0] as CountryCode)),
        data: groupedData.values as Point[],
        type: 'column',
      };
    }) as SeriesOptionsType[]),
  ];

  const options: Highcharts.Options = {
    colors: [
      'var(--chart-grey)',
      'var(--chart-yellow)',
      'var(--chart-revenue-alpha)',
      'var(--chart-blue)',
      'var(--chart-aqua)',
      'var(--chart-purple)',
      'var(--chart-orange)',
    ],
    tooltip: {
      xDateFormat: resolution ? DATE_FORMATS[resolution] : undefined,
      shared: true,
      pointFormatter() {
        const isForecast = new Date(this.x).valueOf() > Date.now();
        const name = forecastName(this.series.name, isForecast);

        return `<span style="color: ${this.color}">●</span> ${appendColon(name, locale)} <b>${
          isANumber(this.y) ? new Intl.NumberFormat(locale, currencyOptions).format(this.y) : '--'
        } ${this.series.options.tooltip?.valueSuffix || ''}</b><br/>`;
      },
    },
    xAxis: { type: 'datetime' },
    yAxis: { title: { text: `${yAxisTitle} (${getSymbol(selectedCurrency || 'EUR')})` }, opposite: true },
    plotOptions: {
      column: {
        stacking: 'normal',
      },
    },
  };

  return <Chart options={options} series={series} isLoading={isLoading} hideLegend />;
};
