import classNames from 'classnames';
// import { SeriesColumnOptions, XAxisOptions } from 'highcharts/highstock';
import {
  GreenstarFocusData,
  GreenstarReportData,
  GreenstarReportDataWithOneFocus,
  GreenstarReportMetricValues,
} from './types';
// import { da } from '@faker-js/faker/.';

export type RiskAnalysisChart = {
  key: string;
  series: Highcharts.Options['series'];
  options: Highcharts.Options;
};

const SPLIT_BY_METRICS_ONLY_COLUMNS = ['MtoM'];
const SPLIT_BY_METRICS_ONLY_LINES = ['Net Price', 'Float Price', 'OA/CfD Price'];
const ALL_SPLIT_BY_ONLY_METRICS = [...SPLIT_BY_METRICS_ONLY_COLUMNS, ...SPLIT_BY_METRICS_ONLY_LINES];

const SENSITIVITY_VOLUME_METRICS = ['Total'];
const VALUATION_VOLUME_METRICS = ['Volume Not Signed', 'Signed Volume Not Hedged', 'Signed Hedged Volume'];
const REGULAR_COLUMN_METRICS = [...VALUATION_VOLUME_METRICS, ...SENSITIVITY_VOLUME_METRICS];
const REGULAR_COLUMN_GROUP1_METRICS = REGULAR_COLUMN_METRICS.filter((metric) => metric !== 'Volume');

const REGULAR_LINE_METRICS = ['HCR'];
const ALL_REGULAR_CHART_METRICS = [...REGULAR_COLUMN_METRICS, ...REGULAR_LINE_METRICS];

export const useRiskAnalysisCharts = ({
  data,
  isReportNested,
  splitByMetric,
  metricsBlackList,
}: {
  data: GreenstarReportData;
  isReportNested: boolean;
  splitByMetric: boolean;
  metricsBlackList: string[];
}): RiskAnalysisChart[] => {
  return isReportNested
    ? createDoubleLevelFocusBasedChart(data, splitByMetric, metricsBlackList)
    : createSingleLevelFocusBasedChart(data, splitByMetric, metricsBlackList);
};

const createSingleLevelFocusBasedChart = (
  data: GreenstarReportData,
  splitByMetric: boolean,
  metricsBlackList: string[],
) => {
  const VALID_METRICS = splitByMetric
    ? [...ALL_REGULAR_CHART_METRICS, ...ALL_SPLIT_BY_ONLY_METRICS]
    : ALL_REGULAR_CHART_METRICS;

  const COLUMN_METRICS = splitByMetric
    ? [...REGULAR_COLUMN_METRICS, ...SPLIT_BY_METRICS_ONLY_COLUMNS]
    : REGULAR_COLUMN_METRICS;

  const charts: RiskAnalysisChart[] = [];

  Object.entries(data).forEach(([focusValue, dateIndexedData]: [string, GreenstarFocusData]) => {
    const dates = Object.keys(dateIndexedData).sort();
    const seriesMap = new Map<string, number[]>();
    dates.forEach((date) => {
      Object.entries(dateIndexedData[date]).forEach(([metric, value]) => {
        const actualMetric = splitByMetric ? focusValue : metric;
        if (!VALID_METRICS.includes(actualMetric)) return;

        let serie = seriesMap.get(metric);
        if (!serie) {
          serie = [];
          seriesMap.set(metric, serie);
        }

        serie.push(Number(value));
      });
    });

    const series = [...seriesMap.entries()]
      .map(([metric, data]) => {
        const actualMetric = splitByMetric ? focusValue : metric;

        return {
          name: metric,
          data,
          stack: REGULAR_COLUMN_GROUP1_METRICS.includes(actualMetric) ? 'group1' : 'group2',
          yAxis: Number(COLUMN_METRICS.includes(actualMetric)),
          type: COLUMN_METRICS.includes(actualMetric) ? 'column' : 'line',
          zIndex: COLUMN_METRICS.includes(actualMetric) ? 1 : 2,
          tooltip: {
            valueSuffix: classNames({
              kWh: VALUATION_VOLUME_METRICS.includes(actualMetric),
              '%': actualMetric === 'HCR',
              '€': SPLIT_BY_METRICS_ONLY_LINES.includes(actualMetric),
            }),
          },
        };
      })
      .sort((a, b) => a.name.localeCompare(b.name))
      .filter((serie) => {
        return splitByMetric ? true : !metricsBlackList.includes(serie.name);
      }) as Highcharts.Options['series'];

    const firstAxisInEuro = splitByMetric && SPLIT_BY_METRICS_ONLY_LINES.includes(focusValue);

    charts.push({
      series,
      key: focusValue,
      options: {
        title: {
          text: focusValue,
          align: 'left',
          style: {
            fontSize: '18px',
          },
        },
        xAxis: {
          type: 'category',
          categories: dates,
        },
        tooltip: {
          formatter: function () {
            let tooltip = '<b>' + this.x + '</b><br>';
            let total = 0;

            (this.points || []).forEach((point) => {
              const name = splitByMetric ? focusValue : point.series.name;

              const unit = classNames({
                kWh: VALUATION_VOLUME_METRICS.includes(name),
                '%': point.series.name === 'HCR',
                '€': SPLIT_BY_METRICS_ONLY_LINES.includes(name),
              });

              tooltip +=
                '<span style="color:' +
                point.color +
                '">\u25CF</span> ' +
                point.series.name +
                ': <b>' +
                point.y +
                ' ' +
                unit +
                '</b><br>';
              if (point.series.name.includes('Volume') || focusValue.includes('Volume')) {
                total += point.y;
              }
            });

            if (total > 0) tooltip += '<br><b>Total Volume: ' + total + ' kWh</b>';

            return tooltip;
          },
          shared: true,
        },
        yAxis: [
          firstAxisInEuro
            ? {
                title: { text: null },
                labels: { format: '{value} €' },
              }
            : {
                title: { text: null },
                min: 0,
                max: 100,
                labels: { format: '{value} %' },
              },
          {
            title: { text: null },
            opposite: true,
          },
        ],
        plotOptions: {
          column: {
            minPointLength: 3,
            stacking: 'normal',
          },
        },
      },
    });
  });

  return splitByMetric
    ? charts.filter(({ key }) => VALID_METRICS.includes(key) && !metricsBlackList.includes(key))
    : charts;
};

const createDoubleLevelFocusBasedChart = (
  data: GreenstarReportData,
  splitByMetric: boolean,
  metricsBlackList: string[],
) => {
  const charts: RiskAnalysisChart[] = [];
  const VALID_METRICS = splitByMetric
    ? [...ALL_REGULAR_CHART_METRICS, ...ALL_SPLIT_BY_ONLY_METRICS]
    : ALL_REGULAR_CHART_METRICS;

  const COLUMN_METRICS = splitByMetric
    ? [...REGULAR_COLUMN_METRICS, ...SPLIT_BY_METRICS_ONLY_COLUMNS]
    : REGULAR_COLUMN_METRICS;

  Object.entries(data).forEach(([focusValue, groupOfDateIndexedData]: [string, GreenstarReportDataWithOneFocus]) => {
    if (splitByMetric && focusValue === 'HCR') return;
    const dateIndexedDataList = Object.entries(groupOfDateIndexedData);

    const dates = Object.keys(
      dateIndexedDataList.reduce((acc, [, value]) => ({ ...acc, ...value }), {} as GreenstarFocusData),
    ).sort();

    const valuesList: GreenstarReportMetricValues[] = [];

    dates.forEach((date) => {
      const values: GreenstarReportMetricValues = {};

      dateIndexedDataList.forEach(([nestedKey, dateIndexedData]) => {
        if (!dateIndexedData[date]) return;
        Object.entries(dateIndexedData[date]).forEach(([metric, value]) => {
          const actualMetric = splitByMetric ? nestedKey : metric;
          values[actualMetric] = Number(values[actualMetric] || 0) + Number(value);
        });
      });

      valuesList.push(values);
    });

    const seriesMap = new Map<string, number[]>();
    const hcrSerie: (number | null)[] = [];
    seriesMap.set('HCR', hcrSerie as number[]);

    valuesList.forEach((values) => {
      Object.entries(values).forEach(([metric, value]) => {
        const actualMetric = splitByMetric ? focusValue : metric;
        // We'll compute HCR ourselves
        if (!VALID_METRICS.includes(actualMetric) || actualMetric == 'HCR') return;

        let serie = seriesMap.get(metric);
        if (!serie) {
          serie = [];
          seriesMap.set(metric, serie);
        }

        serie.push(Number(value));
      });
      const isClickedVolumeDefined = typeof values['Clicked Volume'] !== 'undefined';
      const isVolumeDefined = typeof values['Volume'] !== 'undefined';
      const isHcrComputable = isClickedVolumeDefined && isVolumeDefined;
      const hcr = isHcrComputable ? (100 * Number(values['Clicked Volume'])) / Number(values['Volume']) : null;
      hcrSerie.push(hcr);
    });

    if (hcrSerie.every((hcr) => hcr === null)) {
      seriesMap.delete('HCR');
    }
    const series = [...seriesMap.entries()]
      .map(([metric, data]) => {
        const actualMetric = splitByMetric ? focusValue : metric;

        return {
          name: metric,
          data,
          stack: REGULAR_COLUMN_GROUP1_METRICS.includes(actualMetric) ? 'group1' : 'group2',
          yAxis: Number(COLUMN_METRICS.includes(actualMetric)),
          type: COLUMN_METRICS.includes(actualMetric) ? 'column' : 'line',
          zIndex: COLUMN_METRICS.includes(actualMetric) ? 1 : 2,
          tooltip: {
            valueSuffix: classNames({
              kWh: VALUATION_VOLUME_METRICS.includes(actualMetric),
              '%': actualMetric === 'HCR',
              '€': SPLIT_BY_METRICS_ONLY_LINES.includes(actualMetric),
            }),
          },
        };
      })
      .sort((a, b) => a.name.localeCompare(b.name))
      .filter((serie) => {
        return splitByMetric ? true : !metricsBlackList.includes(serie.name);
      }) as Highcharts.Options['series'];

    const firstAxisInEuro = splitByMetric && SPLIT_BY_METRICS_ONLY_LINES.includes(focusValue);

    charts.push({
      series,
      key: focusValue,
      options: {
        title: { text: focusValue, align: 'left' },
        xAxis: {
          type: 'category',
          categories: dates,
        },
        yAxis: [
          firstAxisInEuro
            ? {
                title: { text: null },
                labels: { format: '{value} €' },
              }
            : {
                title: { text: null },
                min: 0,
                max: 100,
                labels: { format: '{value} %' },
              },
          {
            title: { text: null },
            opposite: true,
          },
        ],
        plotOptions: {
          column: {
            minPointLength: 3,
            stacking: 'normal',
          },
        },
      },
    });
  });

  if (splitByMetric) {
    const splitByMetricCharts = charts.filter(
      ({ key }) => VALID_METRICS.includes(key) && !metricsBlackList.includes(key),
    );

    // const clickedVolumeChart = splitByMetricCharts.find(({ key }) => key === 'Clicked Volume');
    // const volumeChart = splitByMetricCharts.find(({ key }) => key === 'Volume');
    // if (!clickedVolumeChart || !volumeChart) return splitByMetricCharts;

    // const series = (clickedVolumeChart.series as RiskAnalysisChart['series'])?.map((serie, index1) => {
    //   return {
    //     ...serie,
    //     data: (serie as SeriesColumnOptions).data?.map((clickedVolume, index2) => {
    //       const volumeSeries = volumeChart?.series?.[index1] as RiskAnalysisChart['series'];
    //       const volume = volumeSeries?.[index2];

    //       return volume ? (100 * Number(clickedVolume)) / Number(volume) : null;
    //     }),
    //     tooltip: { valueSuffix: '%' },
    //     type: 'line',
    //     yAxis: 1,
    //   };
    // }) as RiskAnalysisChart['series'];

    // const hcrChart: RiskAnalysisChart = {
    //   series,
    //   key: 'HCR',
    //   options: {
    //     title: { text: 'HCR', align: 'left' },
    //     xAxis: {
    //       type: 'category',
    //       categories: (charts[0].options?.xAxis as XAxisOptions).categories,
    //     },
    //     yAxis: [
    //       {
    //         title: { text: null },
    //         min: 0,
    //         max: 100,
    //         labels: { format: '{value} %' },
    //       },
    //       {
    //         title: { text: null },
    //         opposite: true,
    //       },
    //     ],
    //     plotOptions: {
    //       column: {
    //         minPointLength: 3,
    //         stacking: 'normal',
    //       },
    //     },
    //   },
    // };

    return [...splitByMetricCharts /*, hcrChart*/];
  }

  return charts;
};
